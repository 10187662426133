export const TransactionStatus = {
  PENDING: 'PENDING',
  ARCHIVED: 'ARCHIVED',
  COMPLETED: 'COMPLETED',
  TRANSFER_INCOMPLETE: 'TRANSFER_INCOMPLETE',
  INCOMPLETE: 'INCOMPLETE',
  RECONCILED: 'RECONCILED',
  REVERSED: 'REVERSED',
};

export const TransactionRejectionReason = {
  PENDING_PAYMENT: 'PENDING PAYMENT',
  PENDING_INVESTOR_INFORMATION: 'PENDING INVESTOR INFORMATION',
  TRADE_CANCELLED: 'TRADE CANCELLED',
  CLIENT_RECALL: 'CLIENT RECALL',
};
export const IdentificationType = {
  NATIONAL_ID: 'NATIONALID',
  PASSPORT: 'PASSPORT',
};
export const kycStatusValues = [
  {
    id: 1,
    value: 'PENDING',
  },
  {
    id: 2,
    value: 'SUBMITTED',
  },
  {
    id: 3,
    value: 'IN_EDIT',
  },
  {
    id: 4,
    value: 'REJECTED',
  },
  {
    id: 5,
    value: 'COMPLETED',
  },
];

export const PocketTopUpTransactionStatus = [
  { id: 1, value: 'PENDING' },
  { id: 2, value: 'COMPLETED' },
  { id: 3, value: 'ARCHIVED' },
];

export const DisinvestmentTransactionRejectionReason = {
  DISINVESTMENT_CANCELLED_BY_CLIENT: 'DISINVESTMENT_CANCELLED_BY_CLIENT',
};

export const ArchivedRefunded = {
  true: 'REFUNDED',
  false: 'NOT REFUNDED',
};

export const OperationType = {
  ALL: 'ALL',
  DEPOSIT: 'DEPOSIT',
  DISINVESTMENT: 'DISINVESTMENT',
};

export const BlockType = {
  ALL: 'ALL',
  DEPOSIT: 'DEPOSIT',
  WITHDRAW: 'WITHDRAW',
};

export const KycStatus = {
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  COMPLETED: 'COMPLETED',
};

export const TransactionType = {
  GOAL: 'GOAL',
  DIRECT: 'DIRECT',
};

export const AdminPermissions = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  USER_STATUS_CHANGE: 'USER_STATUS_CHANGE',
  USER_VIEW: 'USER_VIEW',
  MANIPULATE_USER_SENSITIVE_INFO: 'MANIPULATE_USER_SENSITIVE_INFO',
  BLOCK_MANIPULATION: 'BLOCK_MANIPULATION',
  BLOCK_COMPLETION: 'BLOCK_COMPLETION',
  BLOCK_VIEW: 'BLOCK_VIEW',
  FUND_VIEW: 'FUND_VIEW',
  SUBSCRIPTION_VIEW: 'SUBSCRIPTION_VIEW',
  SUBSCRIPTION_CONFIGURATION: 'SUBSCRIPTION_CONFIGURATION',
  FUND_CONFIGURATION: 'FUND_CONFIGURATION',
  PAY_FUND_DIVIDENDS_OR_INTEREST: 'PAY_FUND_DIVIDENDS/INTEREST',
  TRANSACTION_VIEW: 'TRANSACTION_VIEW',
  TRANSACTION_STATUS_CHANGE: 'TRANSACTION_STATUS_CHANGE',
  TRANSACTION_REFUND_CHANGE: 'TRANSACTION_REFUND_CHANGE',
  ADD_TRANSACTION: 'ADD_TRANSACTION',
  VOUCHER_VIEW: 'VOUCHER_VIEW',
  VOUCHER_CONFIGURATION: 'VOUCHER_CONFIGURATION',
  PARTNER_CREATION: 'PARTNER_CREATION',
  PARTNER_VIEW: 'PARTNER_VIEW',
  DAILY_TRADE_CONFIGURATION: 'DAILY_TRADE_CONFIGURATION',

  VIEW_POCKETS: 'VIEW_POCKETS',
  POCKETS_MANIPULATION: 'POCKETS_MANIPULATION',

  VIEW_PAYMENT_BATCH: 'VIEW_PAYMENT_BATCH',
  MANIPULATE_PAYMENT_BATCH: 'MANIPULATE_PAYMENT_BATCH',

  VIEW_SETTLEMENT_DETAILS: 'VIEW_SETTLEMENT_DETAILS',
  MANIPULATE_SETTLEMENT_DETAILS: 'MANIPULATE_SETTLEMENT_DETAILS',

  VIEW_CONVERSIONS_RATE: 'VIEW_CONVERSIONS_RATE',
  MANIPULATE_CONVERSIONS_RATE: 'MANIPULATE_CONVERSIONS_RATE',

  VIEW_RECURRING_PAYMENTS: 'VIEW_RECURRING_PAYMENTS',
  MANIPULATE_RECURRING_PAYMENTS: 'MANIPULATE_RECURRING_PAYMENTS',
};

export const AdminStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const UserStatusOptions = [
  'REGISTERED',
  'EMAIL_VALIDATED',
  'DEACTIVATED',
  'BANNED',
];
export const KycStatusOptions = [
  'COMPLETED',
  'PENDING',
  'SUBMITTED',
  'REJECTED',
];

export const UserLevelOptions = ['PLATINUM', 'STANDARD'];

export const UserLevelOptions1 = [
  { id: 1, value: 'PLATINUM' },
  { id: 2, value: 'STANDARD' },
];

export const userKycTiers = [
  { id: 1, value: 'Tier One' },
  { id: 2, value: 'Tier Two' },
  { id: 3, value: 'Tier Three' },
];
export const dashForUnvailableData = '---';

export const BlockStatus = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
};

export const rejectedReasons = {
  WRONG_DOCUMENTS_PROVIDED: 'WRONG DOCUMENTS PROVIDED',
  EXPIRED_DOCUMENTS: 'EXPIRED DOCUMENTS',
  NAME_MISMATCH: 'NAME MISMATCH',
  PROHIBITED_JURISDICTION: 'PROHIBITED JURISDICTION',
};
export const operationFilterValues = [
  { id: 1, value: 'DEBIT' },
  { id: 2, value: 'CREDIT' },
];

export const pocketActionValues = [
  { id: 1, value: 'DISINVESTMENT' },
  { id: 2, value: 'CONVERSION' },
  { id: 3, value: 'TOP_UP' },
  { id: 4, value: 'REFUND' },
  // Related to outgoing transactions
  { id: 5, value: 'WITHDRAW' },
  { id: 6, value: 'INVESTMENT' },
  { id: 7, value: 'DIVIDEND/INTEREST' },
];

export const pocketTransactionSource = {
  WITHDRAW: 'WITHDRAW',
};

export const pocketTransactionStatus = [
  { id: 1, value: 'PENDING' },
  { id: 2, value: 'COMPLETED' },
  { id: 3, value: 'REVERSED' },
  { id: 4, value: 'TRANSFER_INCOMPLETE' },
  { id: 5, value: 'ARCHIVED' },
];

export const pocketWithdrawalTransactionStatus = [
  { id: 1, value: 'PENDING' },
  { id: 2, value: 'REVERSED' },
];

export const pocketTransactionStatusDropdownOptions = {
  PENDING: [
    { id: 1, value: 'PENDING' },
    { id: 2, value: 'COMPLETED' },
    { id: 3, value: 'ARCHIVED' },
  ],

  TRANSFER_INCOMPLETE: [
    { id: 1, value: 'TRANSFER_INCOMPLETE' },
    { id: 2, value: 'COMPLETED' },
    { id: 3, value: 'ARCHIVED' },
  ],

  ARCHIVED: [{ id: 1, value: 'ARCHIVED' }],

  REVERSED: [{ id: 1, value: 'REVERSED' }],
};

export const settlementDetailActions = [
  { id: 1, value: 'SUBMITTED' },
  { id: 2, value: 'REJECTED' },
  { id: 3, value: 'ON_HOLD' },
];
export const settlementDetailActionsMap = {
  SUBMITTED: 'SUBMITTED',
  REJECTED: 'REJECTED',
  ON_HOLD: 'ON_HOLD',
};
export const userStatusValues = [
  { id: 1, value: 'REGISTERED' },
  { id: 2, value: 'EMAIL_VALIDATED' },

  { id: 3, value: 'DEACTIVATED' },
];

export const settlementTypes = [
  {
    id: 1,
    value: 'MPESA',
  },
  {
    id: 2,
    value: 'BANK',
  },
  {
    id: 3,
    value: 'AIRTEL_MONEY',
  },
];

export const settlementDetailTypes = {
  MPESA: 'MPESA',
  BANK: 'BANK',
  AIRTEL_MONEY: 'AIRTEL_MONEY',
};

export const paymentChannels = [
  { id: 1, value: 'AIRTEL_MONEY' },
  { id: 2, value: 'MPESA' },
  { id: 3, value: 'BANK' },
];

export const supportedAdminPaymentChannels = [
  { id: 1, value: 'Mpesa', name: 'MPESA' },
  { id: 2, value: 'BANK_TRANSFER', name: 'Bank Transfer' },
];

export const paymentChannelsMap = {
  AIRTEL: 'AIRTEL',
  MPESA: 'MPESA',
  BANK: 'BANK',
};

export const supportedCurrencies = [
  { id: 1, value: 'KES' },
  { id: 2, value: 'USD' },
  { id: 3, value: 'RWF' },
  { id: 4, value: 'TZS' },
  { id: 5, value: 'EUR' },
  { id: 6, value: 'GBP' },
];

export const TAJIRI_NAME = 'TAJIRI';

export const paymentBlockStatus = {
  OPS_APPROVED: 'OPS_APPROVED',
  FINANCE_APPROVED: 'FINANCE_APPROVED',
};

export const PocketTransactionStatusMap = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  REVERSED: 'REVERSED',
  FAILED: 'FAILED',
  PAID: 'PAID',
};

export const InvestorTypes = {
  INDIVIDUAL: 'INDIVIDUAL',
  BUSINESS: 'BUSINESS',
  CHAMA: 'CHAMA',
};
export const BusinessTypes = {
  SOLE_PROPRIETORSHIP: 'SOLE_PROPRIETORSHIP',
  INCORPORATED_BUSINESS: 'INCORPORATED_BUSINESS',
};

export const incomeTypes = {
  INTEREST: 'INTEREST',
  DIVIDENDS: 'DIVIDEND',
};

export const adminCreateTransactionReason = [
  {
    id: 1,
    value: 'RECONCILIATION_FROM_BANK',
    name: 'Reconciliation from bank',
  },
];
export const recurringPaymentStatus = [
  {
    id: 1,
    value: 'PENDING',
  },

  {
    id: 2,
    value: 'APPROVED',
  },
  {
    id: 3,
    value: 'EXPIRED',
  },
  {
    id: 4,
    value: 'CANCELLED',
  },
];

export const recurringPaymentChannels = [
  {
    id: 1,
    value: 'BANK_TRANSFER',
  },
  {
    id: 2,
    value: 'MPESA',
  },
];

export const corporateActions = {
  STOCK_SPLIT: 'STOCK_SPLIT',
  CONSOLIDATION: 'CONSOLIDATION',
};

export const actionNames = {
  STOCK_SPLIT: 'Split',
  CONSOLIDATION: 'Consolidation',
};
