import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import ErrorCard from '../../../common/ErrorCard';
import { AdminPermissions } from '../../../helpers/constants';
import BackIcon from '../../../icons/backIcon.svg';
import {
  createAdmin,
  getPermissions,
  updatePermission,
} from '../_redux/adminCrud';

const Permissions = props => {
  const { adminId } = props.match.params;
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [adminData, setAdminData] = useState({});
  const [permissionsData, setPermissionsData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const location = useLocation();
  const history = useHistory();

  const handleAlert = (message, iconType) => {
    Swal.fire({
      icon: iconType,
      position: 'top-end',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
      text: message,
      showConfirmButton: false,
      timer: 1800,
    });
  };

  const handleChange = value => {
    const newData = [...permissionsData];
    const valueExist = newData.includes(value);
    if (valueExist) {
      const index = newData.indexOf(value);
      newData.splice(index, 1);
      setPermissionsData(newData);
    } else {
      setPermissionsData([...permissionsData, value]);
    }
  };

  const getAdmin = async () => {
    try {
      setLoading(true);
      if (
        location.pathname === '/admins/set-permissions' &&
        location.state.values.names &&
        location.state.values.email
      ) {
        setAdminData(location.state.values);
        setLoading(false);
      } else {
        const res = await getPermissions(adminId);
        if (res.status === 200) {
          setAdminData(res.data.data);
          setPermissionsData(res.data.data.permissions);
          setLoading(false);
        }
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const updatePermissionData = async () => {
    try {
      setLoadingData(true);
      let res = '';
      if (location.pathname === '/admins/set-permissions') {
        res = await createAdmin({ permissions: permissionsData, ...adminData });
        if (res.status === 201) setLoadingData(false);
        handleAlert(res.data.message, 'success');
      } else {
        // Validate and remove permissions that are no longer supported if any
        // FYI: This case occur when a permission enum is renamed or completely removed while existing user already was assigned that permission
        const values = Object.values(AdminPermissions);
        const validPermissions = permissionsData.filter(i =>
          values.includes(i),
        );

        res = await updatePermission(adminId, validPermissions);
        if (res.status === 200) setLoadingData(false);
        handleAlert(res.data.message, 'success');
      }
      setTimeout(() => history.push('/admins'), 3000);
    } catch (error) {
      setLoadingData(false);
      handleAlert(error.response.data.message, 'error');
    }
  };

  useEffect(() => {
    getAdmin();
  }, []);

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }
  return (
    <>
      {errorMessage ? (
        <ErrorCard backUrl={'/'} errorMessage={errorMessage} />
      ) : (
        <>
          <Link
            to={
              location.pathname === '/admins/set-permissions'
                ? '/admin-create'
                : '/admins'
            }
          >
            <img src={BackIcon} alt={'Back icon'} />
            <span className="ml-2">Back</span>
          </Link>
          <div className="card card-custom card-stretch gutter-b mt-2">
            <div className="card-header pt-7">
              <h4>{adminData?.names}'s Permissions</h4>
            </div>
            <div className="card-body mb-20">
              <div>
                <h4 className="mb-15">Users</h4>
                <div className="form-group row">
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() => handleChange(AdminPermissions.USER_VIEW)}
                      checked={permissionsData.includes(
                        AdminPermissions.USER_VIEW,
                      )}
                      className="form-check-input"
                      id="user_view"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="user_view"
                    >
                      View Users
                    </label>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(AdminPermissions.USER_STATUS_CHANGE)
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.USER_STATUS_CHANGE,
                      )}
                      className="form-check-input"
                      id="user_status"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="user_status"
                    >
                      Manipulate User's Status
                    </label>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="update_user_sensitive_info"
                      onChange={() =>
                        handleChange(
                          AdminPermissions.MANIPULATE_USER_SENSITIVE_INFO,
                        )
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.MANIPULATE_USER_SENSITIVE_INFO,
                      )}
                      className="form-check-input"
                      id="update_user_sensitive_info"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="update_user_sensitive_info"
                    >
                      Manipulate User's Sensitive Information
                    </label>
                  </div>
                </div>
              </div>
              <div className="separator separator-dashed my-10" />
              <div>
                <h4 className="mb-15">Blocks</h4>
                <div className="form-group row">
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() => handleChange(AdminPermissions.BLOCK_VIEW)}
                      checked={permissionsData.includes(
                        AdminPermissions.BLOCK_VIEW,
                      )}
                      className="form-check-input"
                      id="block_view"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      for="block_view"
                    >
                      View Blocks
                    </label>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(AdminPermissions.BLOCK_MANIPULATION)
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.BLOCK_MANIPULATION,
                      )}
                      className="form-check-input"
                      id="block_manipulation"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="block_manipulation"
                    >
                      Manipulate Blocks
                    </label>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(AdminPermissions.BLOCK_COMPLETION)
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.BLOCK_COMPLETION,
                      )}
                      className="form-check-input"
                      id="block_completion"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="block_completion"
                    >
                      Complete Blocks
                    </label>
                  </div>
                </div>
              </div>
              <div className="separator separator-dashed my-10" />
              <div>
                <h4 className="mb-15">Daily Trades</h4>
                <div className="form-group row">
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(AdminPermissions.DAILY_TRADE_CONFIGURATION)
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.DAILY_TRADE_CONFIGURATION,
                      )}
                      className="form-check-input"
                      id="daily_trades_view"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="daily_trades_view"
                    >
                      Manipulate Daily Trades
                    </label>
                  </div>
                </div>
              </div>
              <div className="separator separator-dashed my-10" />
              <div>
                <h4 className="mb-15">Funds</h4>
                <div className="form-group row">
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() => handleChange(AdminPermissions.FUND_VIEW)}
                      checked={permissionsData.includes(
                        AdminPermissions.FUND_VIEW,
                      )}
                      className="form-check-input"
                      id="funds_view"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="funds_view"
                    >
                      View Funds
                    </label>
                  </div>

                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(AdminPermissions.FUND_CONFIGURATION)
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.FUND_CONFIGURATION,
                      )}
                      className="form-check-input"
                      id="funds_config"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="funds_config"
                    >
                      Manipulate Funds
                    </label>
                  </div>

                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="fundDividends"
                      onChange={() =>
                        handleChange(
                          AdminPermissions.PAY_FUND_DIVIDENDS_OR_INTEREST,
                        )
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.PAY_FUND_DIVIDENDS_OR_INTEREST,
                      )}
                      className="form-check-input"
                      id="funds_config"
                    />

                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="funds_config"
                    >
                      Pay Fund Dividends/Interest
                    </label>
                  </div>
                </div>
              </div>

              <div className="separator separator-dashed my-10" />
              <div>
                <h4 className="mb-15">Corporate Actions</h4>
                <div className="form-group row">
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="corporate_action"
                      onChange={() => handleChange(AdminPermissions.FUND_VIEW)}
                      checked={permissionsData.includes(
                        AdminPermissions.FUND_VIEW,
                      )}
                      className="form-check-input"
                      id="corporate_action_view"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="corporate_action_view"
                    >
                      View Corporate Actions
                    </label>
                  </div>

                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="manipulate_corporate_action"
                      onChange={() =>
                        handleChange(AdminPermissions.FUND_CONFIGURATION)
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.FUND_CONFIGURATION,
                      )}
                      className="form-check-input"
                      id="manipulate_corporate_action"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="manipulate_corporate_action"
                    >
                      Manipulate Corporate Actions
                    </label>
                  </div>
                </div>
              </div>
              <div className="separator separator-dashed my-10" />
              <div>
                <h4 className="mb-15">Subscription</h4>
                <div className="form-group row">
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(AdminPermissions.SUBSCRIPTION_VIEW)
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.SUBSCRIPTION_VIEW,
                      )}
                      className="form-check-input"
                      id="subscription_view"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="subscription_view"
                    >
                      View Subscription
                    </label>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(
                          AdminPermissions.SUBSCRIPTION_CONFIGURATION,
                        )
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.SUBSCRIPTION_CONFIGURATION,
                      )}
                      className="form-check-input"
                      id="subscription_config"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="subscription_config"
                    >
                      Manipulate Subscription
                    </label>
                  </div>
                </div>
              </div>
              <div className="separator separator-dashed my-10" />
              <div>
                <h4 className="mb-15">Transactions</h4>
                <div className="form-group row">
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(AdminPermissions.TRANSACTION_VIEW)
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.TRANSACTION_VIEW,
                      )}
                      className="form-check-input"
                      id="view_transaction"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="view_transaction"
                    >
                      View Transactions
                    </label>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(AdminPermissions.TRANSACTION_STATUS_CHANGE)
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.TRANSACTION_STATUS_CHANGE,
                      )}
                      className="form-check-input"
                      id="transaction_status"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="transaction_status"
                    >
                      Change Transactions Status
                    </label>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="transaction_add"
                      onChange={() =>
                        handleChange(AdminPermissions.ADD_TRANSACTION)
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.ADD_TRANSACTION,
                      )}
                      className="form-check-input"
                      id="transaction_add"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="transaction_add"
                    >
                      Add Transaction
                    </label>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(AdminPermissions.TRANSACTION_REFUND_CHANGE)
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.TRANSACTION_REFUND_CHANGE,
                      )}
                      className="form-check-input"
                      id="transaction_refund"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="transaction_refund"
                    >
                      Manipulate Transactions Refund
                    </label>
                  </div>
                </div>
              </div>
              <div className="separator separator-dashed my-10" />
              <div>
                <h4 className="mb-15">Recurring Payments</h4>
                <div className="form-group row">
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(AdminPermissions.VIEW_RECURRING_PAYMENTS)
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.VIEW_RECURRING_PAYMENTS,
                      )}
                      className="form-check-input"
                      id="view_recurring_payments"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="view_recurring_payments"
                    >
                      View Recurring Payments
                    </label>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(
                          AdminPermissions.MANIPULATE_RECURRING_PAYMENTS,
                        )
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.MANIPULATE_RECURRING_PAYMENTS,
                      )}
                      className="form-check-input"
                      id="recurring_payments_config"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="recurring_payments_config"
                    >
                      Manipulate Recurring Payments
                    </label>
                  </div>
                </div>
              </div>
              <div className="separator separator-dashed my-10" />
              <div>
                <h4 className="mb-15">Settlement Details</h4>
                <div className="form-group row">
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(AdminPermissions.VIEW_SETTLEMENT_DETAILS)
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.VIEW_SETTLEMENT_DETAILS,
                      )}
                      className="form-check-input"
                      id="view_settlement"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="view_settlement"
                    >
                      View Settlement details
                    </label>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(
                          AdminPermissions.MANIPULATE_SETTLEMENT_DETAILS,
                        )
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.MANIPULATE_SETTLEMENT_DETAILS,
                      )}
                      className="form-check-input"
                      id="settlement_status"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="settlement_status"
                    >
                      Manipulate settlement details
                    </label>
                  </div>
                </div>
              </div>
              <div className="separator separator-dashed my-10" />
              <div>
                <h4 className="mb-15">Partners</h4>
                <div className="form-group row">
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(AdminPermissions.PARTNER_VIEW)
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.PARTNER_VIEW,
                      )}
                      className="form-check-input"
                      id="partner_view"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="partner_view"
                    >
                      View Partners
                    </label>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(AdminPermissions.PARTNER_CREATION)
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.PARTNER_CREATION,
                      )}
                      className="form-check-input"
                      id="partner_creation"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="subscription_config"
                    >
                      Create Partner
                    </label>
                  </div>
                </div>
              </div>
              <div className="separator separator-dashed my-10" />
              <div>
                <h4 className="mb-15">Vouchers</h4>
                <div className="form-group row">
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(AdminPermissions.VOUCHER_VIEW)
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.VOUCHER_VIEW,
                      )}
                      className="form-check-input"
                      id="view_voucher"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="view_voucher"
                    >
                      View Vouchers
                    </label>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(AdminPermissions.VOUCHER_CONFIGURATION)
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.VOUCHER_CONFIGURATION,
                      )}
                      className="form-check-input"
                      id="voucher_config"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="voucher_config"
                    >
                      Manipulate Vouchers
                    </label>
                  </div>
                </div>
              </div>

              <div className="separator separator-dashed my-10" />
              <div>
                <h4 className="mb-15">Pockets</h4>
                <div className="form-group row">
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(AdminPermissions.VIEW_POCKETS)
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.VIEW_POCKETS,
                      )}
                      className="form-check-input"
                      id="view_voucher"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="view_voucher"
                    >
                      View Pocket Transactions
                    </label>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(AdminPermissions.POCKETS_MANIPULATION)
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.POCKETS_MANIPULATION,
                      )}
                      className="form-check-input"
                      id="voucher_config"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="voucher_config"
                    >
                      Manipulate Pocket transactions
                    </label>
                  </div>
                </div>
              </div>

              <div className="separator separator-dashed my-10" />
              <div>
                <h4 className="mb-15">Payment Batch</h4>
                <div className="form-group row">
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(AdminPermissions.VIEW_PAYMENT_BATCH)
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.VIEW_PAYMENT_BATCH,
                      )}
                      className="form-check-input"
                      id="view_voucher"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="view_voucher"
                    >
                      View Payment Batch
                    </label>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(AdminPermissions.MANIPULATE_PAYMENT_BATCH)
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.MANIPULATE_PAYMENT_BATCH,
                      )}
                      className="form-check-input"
                      id="voucher_config"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="voucher_config"
                    >
                      Manipulate payment batch
                    </label>
                  </div>
                </div>
              </div>

              <div className="separator separator-dashed my-10" />
              <div>
                <h4 className="mb-15">Conversion</h4>
                <div className="form-group row">
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(AdminPermissions.VIEW_CONVERSIONS_RATE)
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.VIEW_CONVERSIONS_RATE,
                      )}
                      className="form-check-input"
                      id="view_conversions"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="view_conversions"
                    >
                      View Conversions
                    </label>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(
                          AdminPermissions.MANIPULATE_CONVERSIONS_RATE,
                        )
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.MANIPULATE_CONVERSIONS_RATE,
                      )}
                      className="form-check-input"
                      id="conversions_config"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="conversions_config"
                    >
                      Manipulate conversions
                    </label>
                  </div>
                </div>
              </div>

              <div className="separator separator-dashed my-10" />
              <div>
                <h4 className="mb-15">Super Admin</h4>
                <div className="form-group row">
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="checkbox"
                      name="investable"
                      onChange={() =>
                        handleChange(AdminPermissions.SUPER_ADMIN)
                      }
                      checked={permissionsData.includes(
                        AdminPermissions.SUPER_ADMIN,
                      )}
                      className="form-check-input"
                      id="super_admin"
                    />
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="super_admin"
                    >
                      Super Admin Privileges
                    </label>
                  </div>
                </div>
              </div>
              {grantPermission(AdminPermissions.SUPER_ADMIN) ? (
                <div className="mt-15 font-weight-bold text-center">
                  <button
                    className="btn  text-white  font-weight-bold px-15 py-4"
                    onClick={updatePermissionData}
                    style={{ background: '#0071CE' }}
                    disabled={
                      permissionsData && permissionsData.length > 0
                        ? false
                        : true
                    }
                  >
                    Save
                    {loadingData && (
                      <span className="ml-3 spinner spinner-white" />
                    )}
                  </button>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Permissions;
